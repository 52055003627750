import { rem } from 'polished';
import styled from 'styled-components';

export const Body = styled.a`
  position: relative;
  z-index: 1;
  display: inline-grid;
  grid-gap: ${rem(16)};
  grid-template-columns: auto 1fr;
  padding: ${rem(24)} ${rem(28)};
  text-align: left;
  text-decoration: none;
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: transform 0.25s ease;

  * {
    color: ${({ theme }) => theme.colors.text};
  }

  ${({ theme }) => theme.mediaQueries.medium} {
    grid-template-rows: repeat(2, auto);
    grid-template-columns: 1fr;
    padding: ${rem(40)} ${rem(28)};
    text-align: center;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #fff 0%, #dadada 100%);
    border-radius: inherit;
    opacity: 0;
    transition: opacity 0.25s ease;
    content: '';
  }

  &:hover {
    transform: scale(1.04);

    &::after {
      opacity: 1;
    }
  }
`;

export const Content = styled.div`
  position: relative;

  > * {
    display: block;
    margin: 0;
  }

  p {
    margin: 0;
    margin-top: ${rem(2)};
    opacity: 0.8;

    ${({ theme }) => theme.mediaQueries.medium} {
      margin-top: ${rem(8)};
    }
  }
`;

export const IconWrapper = styled.div`
  position: relative;
  align-self: center;
  width: 40px;
  height: 40px;

  ${({ theme }) => theme.mediaQueries.medium} {
    justify-self: center;
    width: 60px;
    height: 60px;
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const PlanCardGrid = styled.div`
  display: grid;
  grid-gap: ${rem(16)};
  align-items: center;

  ${({ theme }) => theme.mediaQueries.medium} {
    grid-template-rows: 1fr auto 1fr;
    grid-template-columns: repeat(3, 1fr);
    max-width: 680px;
    margin: 0 auto;

    > * {
      grid-row: 2 / span 1;
    }

    > *:nth-child(2) {
      grid-column: 1 / span 1;
    }

    > *:nth-child(1) {
      grid-row: 1 / span 3;
      grid-column: 2 / span 1;
      height: 100%;

      ${IconWrapper} {
        align-self: flex-end;
      }
    }
  }
`;

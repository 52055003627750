import { H1, H2 } from 'components/headings';
import { Section, SectionProps } from 'components/ui/section';
import { SectionWrapper } from 'components/ui/section-wrapper';
import { textStyles } from 'components/ui/text';
import { hideVisually, rem } from 'polished';
import styled from 'styled-components';

const DEFAULT_HERO_IMAGE = 'hero-large-v2';
interface HeroProps extends SectionProps {
  heroImage?: string;
}

export const StyledSection = styled(Section)<HeroProps>`
  --hero-image: ${({ heroImage }) =>
    heroImage ? heroImage : DEFAULT_HERO_IMAGE};

  position: relative;
  display: flex;
  align-items: center;
  min-height: 80vh;
  background-image: ${({ heroImage }) =>
    heroImage
      ? `url(${require('../../assets/images/' + heroImage + '-small-v2.jpg')}) `
      : `url(${require('../../assets/images/hero-small-v2.jpg')}) `};
  background-position: center;
  background-size: cover;

  ${({ theme }) => theme.mediaQueries.medium} {
    background-image: ${({ heroImage }) =>
      heroImage
        ? `url(${require('../../assets/images/' + heroImage + '-large.jpg')}) `
        : `url(${require('../../assets/images/hero-large-v2.jpg')}) `};
  }

  &::before,
  &::after {
    position: absolute;
    left: 0;
    width: 100%;
    height: 40%;
    content: '';
  }

  ${SectionWrapper} {
    position: relative;

    > * + * {
      margin-top: ${rem(20)};

      ${({ theme }) => theme.mediaQueries.medium} {
        margin-top: ${rem(60)};
      }
    }
  }
`;

interface HeadingProps {
  smallTitle: boolean;
}

export const HeadingWrapper = styled.div<HeadingProps>`
  max-width: ${({ smallTitle }) => (smallTitle ? '220px' : '460px')};
  margin: 0 auto;
  margin-top: ${rem(30)};

  ${({ theme }) => theme.mediaQueries.medium} {
    margin-top: 0;
  }

  &::after {
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 38%;
    background-image: url(${require('../../assets/images/got-15-minutes-to-burn-small.png')});
    background-size: cover;
    content: '';

    ${({ theme }) => theme.mediaQueries.medium} {
      background-image: url(${require('../../assets/images/got-15-minutes-to-burn.png')});
    }
  }

  ${H1} {
    ${hideVisually}
  }
`;

export const SubHeading = styled(H2)`
  margin-bottom: 0;
  font-size: ${rem(48)};

  ${({ theme }) => theme.mediaQueries.medium} {
    font-size: ${rem(80)};
  }
`;

export const Content = styled.div`
  * {
    ${textStyles}

    margin: 0;
    font-size: ${rem(18)};

    ${({ theme }) => theme.mediaQueries.medium} {
      font-size: ${rem(24)};
    }

    p + p {
      margin-top: ${rem(20)};

      ${({ theme }) => theme.mediaQueries.medium} {
        margin-top: ${rem(30)};
      }
    }

    &:last-child {
      margin-bottom: ${rem(40)};

      ${({ theme }) => theme.mediaQueries.medium} {
        margin-bottom: ${rem(100)};
      }
    }
  }
`;

import { ReactComponent as LeaveIcon } from 'assets/icons/icon-leave-early.svg';
import { ReactComponent as StayIcon } from 'assets/icons/icon-stay-defend.svg';
import { ReactComponent as HelpIcon } from 'assets/icons/icon-undecided.svg';
import { H4 } from 'components/headings';
import Link from 'next/link';
import React, { FC } from 'react';
import { Body, Content, IconWrapper } from './plan-card.styles';

interface Props {
  icon: 'stay' | 'leave' | 'help';
  title: string;
  text: string;
  href: string;
  as?: string;
  testId?: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const PlanCard: FC<Props> = ({
  title,
  text,
  href,
  icon,
  onClick,
  as,
  testId,
}) => {
  return (
    <Link href={href} as={as} passHref>
      <Body onClick={onClick} data-testid={testId}>
        <IconWrapper>
          {icon === 'stay' && <StayIcon title='Stay' />}
          {icon === 'leave' && <LeaveIcon title='Leave' />}
          {icon === 'help' && <HelpIcon title='Help' />}
        </IconWrapper>
        <Content>
          <H4 as='h3'>{title}</H4>
          <p>{text}</p>
        </Content>
      </Body>
    </Link>
  );
};

export default PlanCard;

import { H1 } from 'components/headings';
import PlanCard from 'components/plan-card';
import { PlanCardGrid } from 'components/plan-card/plan-card.styles';
import SwitchTheme from 'components/switch-theme';
import { SectionWrapper } from 'components/ui/section-wrapper';
import { FC, ReactNode } from 'react';
import { LeaveEarlyIntroModeEnum } from 'types/base';
import APP_ROUTES from 'utils/routes';
import {
  Content,
  HeadingWrapper,
  StyledSection,
  SubHeading,
} from './hero.styles';

interface HeroProps {
  smallTitle?: boolean;
  subheading?: string;
  heroImage?: string;
  children: ReactNode;
}

const Hero: FC<HeroProps> = ({
  children,
  subheading,
  heroImage,
  smallTitle = false,
}) => {
  return (
    <SwitchTheme to='light'>
      <StyledSection textAlign='center' heroImage={heroImage}>
        <SectionWrapper>
          <HeadingWrapper smallTitle={smallTitle}>
            <H1>Got 15 minutes to burn?</H1>
          </HeadingWrapper>
          {subheading && <SubHeading>{subheading}</SubHeading>}
          <Content>{children}</Content>
          <PlanCardGrid>
            <PlanCard
              title='Leave early'
              text={`Your safest choice.`}
              as={APP_ROUTES.leave.index}
              testId='leave-default'
              href={`${APP_ROUTES.leave.index}?mode=${LeaveEarlyIntroModeEnum.Default}`}
              icon='leave'
            />
            <PlanCard
              title='Stay and defend'
              text={`Only if you’re well prepared.`}
              href={APP_ROUTES.stay.index}
              icon='stay'
            />
            <PlanCard
              title='Help me decide'
              text={`Not sure? Let us help you.`}
              as={APP_ROUTES.leave.index}
              href={`${APP_ROUTES.leave.index}?mode=${LeaveEarlyIntroModeEnum.Help}`}
              icon='help'
            />
          </PlanCardGrid>
        </SectionWrapper>
      </StyledSection>
    </SwitchTheme>
  );
};

export default Hero;
